import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { isAuthenticated } from './services/authService';
import Loader from './components/loader/Loader';

const AppLayout = React.lazy(() => import('./layouts/AppLayout'));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));
const SignIn = React.lazy(() => import('./pages/signIn/SignIn'));
const ForgotPassword = React.lazy(() => import('./pages/signIn/ForgotPassword'));
const User = React.lazy(() => import('./pages/User'));
const RatingsTable = React.lazy(() => import('./pages/ratings/RatingsTable'));
const RejectedClips = React.lazy(() => import('./pages/RejectedClips'));
const EnterResetCode = React.lazy(() => import('./pages/signIn/EnterResetCode'));
const ResetPassword = React.lazy(() => import('./pages/signIn/ResetPassword'));

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/signin" />;
  }
  return <>{children}</>;
};

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-otp" element={<EnterResetCode />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/" element={<Navigate to="/signin" />} />

          <Route path="/" element={<PrivateRoute><AppLayout /></PrivateRoute>}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<User />} />
            <Route path="ratings" element={<RatingsTable />} />
            <Route path="rejectedClips" element={<RejectedClips />} />
          </Route>

          <Route path="*" element={<Navigate to={isAuthenticated() ? "/dashboard" : "/signin"} />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
