import jwt_decode from "jwt-decode";
import apiClient from "./apiClient";

export const signIn = async (email: string, password: string) => {
  try {
    const response = await apiClient.post("/signin", { email, password });
    const data = response.data;

    localStorage.setItem("authToken", data.token);
    console.log(localStorage.getItem("authToken"));

    return data;
  } catch (error) {
    throw new Error("Authentication failed. Please check your credentials.");
  }
};

export const getCurrentUser = () => {
  const token = localStorage.getItem("authToken");
  if (token) {
    try {
      const decoded: any = jwt_decode(token);
      return {
        name: decoded.name,
        role: decoded.role,
      };
    } catch (e) {
      console.error("Invalid token", e);
      return null;
    }
  }
  return null;
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("authToken");
  return token ? true : false;
};

export const logout = () => {
  localStorage.removeItem("authToken");
  sessionStorage.removeItem("authToken");
};

export const sendPasswordResetEmail = async (email: string) => {
  try {
    const response = await apiClient.post("/auth/forgot-password", { email });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to send reset email");
    }
  } catch (error) {
    throw new Error("Error while sending reset email");
  }
};

export const requestPasswordReset = async (email: string): Promise<void> => {
  try {
    const response = await apiClient.post("/request-password-reset", { email });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to send reset email");
    }
  } catch (error: any) {
    const message =
      error.response?.data?.message || "Failed to request password reset.";
    throw new Error(message);
  }
};

export const verifyResetOtp = async (
  email: string,
  otp: number
): Promise<void> => {
  try {
    const response = await apiClient.post("/verify-otp", { email, otp });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to send reset email");
    }
  } catch (error: any) {
    const message = error.response?.data?.message || "Invalid reset OTP.";
    throw new Error(message);
  }
};

export const resetPassword = async (email: string, password: string) => {
  try {
    const response = await apiClient.post("/reset-password", {
      email,
      password,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to send reset email");
    }
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Password reset failed.");
  }
};
