import React, { FC, ReactElement } from "react";
import { Backdrop, Typography } from "@mui/material";
import loader from "../../assets/loader.gif";

const Loader: FC = (): ReactElement => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Backdrop
      open={open}
      onClick={handleClose}
      sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
    >
      <img src={loader} alt="Loader" style={{ width: 100, height: 100 }} />
      <Typography variant="h4" color="inherit" sx={{ ml: 2 }}></Typography>
    </Backdrop>
  );
};

export default Loader;
